import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

const customAxios = axios.create({
  baseURL: "https://api.softbet9.com/api",
});
// https://api.softbet9.com/api
// http://localhost:8800/api
// http://65.0.73.208:8800/api
customAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

customAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    } else if (error.response && error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/login";
    }
  }
);

export default customAxios;
