import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "./authAxios";
import { useNavigate } from "react-router-dom";
import AppRoutes from "./Routes";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const [token, setToken] = useState(Cookies.get("jwtToken"));
  const navigate = useNavigate();

  useEffect(() => {
    const jwtToken = Cookies.get("jwtToken");
    setToken(jwtToken);
    axios.defaults.headers.common["Authorization"] = "Bearer " + jwtToken;
  }, [Cookies.get("jwtToken")]);

  useEffect(()=>{
     const disableRightClick = (e) => e.preventDefault();
     document.addEventListener('contextmenu', disableRightClick);

     return () => document.removeEventListener('contextmenu', disableRightClick);
  },[])

  // useEffect(() => {


  //   const handleKeyDown = (e) => {
  //     // alert('right click is not allowed')
  //     if (
  //       (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'i' || e.key === 'J' || e.key === 'j')) || // Ctrl+Shift+I
  //       (e.ctrlKey && e.key === 'U') || // Ctrl+U
  //       e.key === 'F12' // F12
  //     ) {
  //       e.preventDefault();
  //     }
  //     if (e.key === 'F12') { // F12
  //       e.preventDefault();
  //     }
  //   };
  //   document.addEventListener('keydown', handleKeyDown);


  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);

  useEffect(() => {
    const iframe = document.getElementById('myIframe');

    if (iframe && iframe.contentWindow) {
      iframe.onload = () => {
        const iframeDocument = iframe.contentWindow.document;

        // Disable right-click
        iframeDocument.addEventListener('contextmenu', (e) => e.preventDefault());

        // Disable keyboard shortcuts
        iframeDocument.addEventListener('keydown', (e) => {
          if (
            (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'i')) || // Ctrl+Shift+I
            (e.ctrlKey && e.key === 'U') || // Ctrl+U
            e.key === 'F12' // F12
          ) {
            e.preventDefault();
          }
        });
      };
    }
  }, []);

  return <ThemeProvider theme={darkTheme}><AppRoutes /></ThemeProvider>;
}

export default App;
