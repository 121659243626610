import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import "./app.scss"
import Cookies from "js-cookie";
import PageLoader from "./Componant/Loader/PageLoader";

function lazyWithDelay(importFunc, delay = 0) {
  return React.lazy(() =>
    Promise.all([
      importFunc(),
      new Promise((resolve) => setTimeout(resolve, delay)),
    ]).then(([module]) => module)
  );
}

const Home = lazyWithDelay(() => import("./Pages/Home/Home"),2000);
const Login = lazyWithDelay(() => import("./Pages/Login/Login"));
const Rules = lazyWithDelay(() => import("./Pages/Rules/Rules"));
const LiveCasino = lazyWithDelay(() => import("./Pages/LiveCasino/LiveCasino"));
const Inplay = lazyWithDelay(() => import("./Pages/InPlay/Inplay"));
const Ledger = lazyWithDelay(() => import("./Pages/Ledger/Ledger"));
const Statement = lazyWithDelay(() => import("./Pages/Statement/Statement"));
const HomeLayout = lazyWithDelay(() => import("./Layout/HomeLayout"));
const Match = lazyWithDelay(() => import("./Pages/InPlay/Match/Match"));
const ChangePassword = lazyWithDelay(() => import("./Pages/ChangePassword/ChangePassword"));
const SingleMatchLedger = lazyWithDelay(() => import("./Pages/Ledger/SingleMatch/SingleMatchLedger"));
const NotFound = lazyWithDelay(() => import("./Pages/NotFound/NotFound"));
const UpcommingMatches = lazyWithDelay(() => import("./Pages/UpcommingMatches/UpcommingMatches"));
const Header = lazyWithDelay(() => import("./Pages/Header/Header"));
const Footer = lazyWithDelay(() => import("./Pages/Footer/Footere"));
const Sidebar = lazyWithDelay(() => import("./Pages/Sidebars/Sidebars"));
const MatchesList = lazyWithDelay(() => import("./Pages/Matches/match"));
const MatchCard = lazyWithDelay(() => import("./Pages/Matches/matchcard"));
const MyMarket = lazyWithDelay(() => import("./Pages/MyMarket/MyMarket"));

const AppRoutes = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  // const [token,setToken]=useState(Cookies.get("jwtToken"));
  const token=Cookies.get("jwtToken");
  const isUserLoggedIn=localStorage.getItem("isUserLoggedIn");
  

  // Check if the current route is the login route
  // const [isLoginPage,setIsLoginPage] = useState(location.pathname === "/login");
  const isLoginPage = location.pathname === "/login";
  const [isHomePage,setisHomePage] = useState(location.pathname === "/");
  // const isRedirectedToLogin = !token || !isUserLoggedIn;

  return (
    <div>
      <Suspense fallback={<PageLoader/> }>
      {!isLoginPage && <Header onMenuClick={toggleSidebar} />}
      {!isLoginPage && (
        <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
      )}
      {/* <PageLoader/>  */}
      <Routes>
        {token && isUserLoggedIn ? (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/rules"
              element={<HomeLayout component={<Rules />} />}
            />
            <Route
              path="/upcoming"
              element={<HomeLayout component={<UpcommingMatches />} />}
            />
            <Route
              path="/livecasino"
              element={<HomeLayout component={<LiveCasino />} />}
            />
            <Route
              path="/inplay"
              element={<HomeLayout component={<Inplay />} />}
            />
            <Route
              path="/ledger"
              element={<HomeLayout component={<Ledger />} />}
            />
            <Route
              path="/statement"
              element={<HomeLayout component={<Statement />} />}
            />
            <Route
              path="/change-password"
              element={<HomeLayout component={<ChangePassword />} />}
            />
            <Route
              path="/match/:match_id/sport/:sport_id"
              element={<HomeLayout component={<Match />} />}
            />
            <Route
              path="/ledger/:match_id"
              element={<HomeLayout component={<SingleMatchLedger />} />}
            />
            <Route
              path="/live/:live_id"
              element={<HomeLayout component={<MatchesList />} />}
            />
            <Route
              path="/my-market"
              element={<HomeLayout component={<MyMarket />} />}
            />
            <Route path="*" element={<NotFound />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
            <Route path="/login" element={<Login />} />
          </>
        )}
      </Routes>
      {!isLoginPage && <Footer />}
      </Suspense>
    </div>
  );
};

export default AppRoutes;
